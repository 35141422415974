import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide62/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide62/image/img.png"
import slider1 from "@components/pageGuide/guides/guide62/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide62/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide62/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Cómo armar un speech de ventas para seguros 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    Un speech de ventas bien estructurado puede diferencia entre cerrar una venta o perder un cliente potencial. Sabes que cada interacción cuenta y que un speech de ventas efectivo no solo debe informar, sino también conectar emocionalmente con el cliente. 
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
Donde las decisiones se toman rápido y las oportunidades de negocio son vastas, dominar el arte de un speech de ventas efectivo es crucial.  
<br /><br />
Te enseñaremos a como construir un speech efectivo que no solo informe, sino que también convierta. Desde evitar presentaciones débiles hasta convertir objeciones en oportunidades, te daremos todas las herramientas para perfeccionar tu presentación.
</p>
)

const data = {
  start: {
    support: "Guía | Banca y seguros",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Estructura del speech de ventas.",
      },
      {
        key: 2,
        text: "Cómo personalizar la comunicación.",
      },
      {
        key: 3,
        text: "Pasos para evitar las objeciones de ventas.",
      },
      {
        key: 4,
        text: "Mejores prácticas en llamadas de ventas.",
      },
      {
        key: 5,
        text: "Ejemplos de speech para ventas de seguros.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
